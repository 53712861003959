import React, { useState, useReducer, useEffect } from "react";
import ServerSlugs from '../constants/server-slugs.json'
import UserPlus from "../SVG/user-plus.svg"
import UserMinus from "../SVG/users-minus.svg"
import queryString from "query-string";


export default function CustomGuildForm({ guilds, setGuilds }) {
    const [isOpen, setIsOpen] = useState(false);
    const [potentialGuilds, setPotentialGuilds] = useReducer(modifyGuilds, [...guilds["Custom"]])

    // form inputs
    const [selectedServer, setSelectedServer] = useState("");
    const [guildInput, setGuildInput] = useState('');

    let selectOptions = Object.keys(ServerSlugs)
        .sort((a, b) => { return a.localeCompare(b) })
        .map(server => {
            return <option key={server} value={ServerSlugs[server]}>{server}</option>
        })

    useEffect(() => {
        if (selectedServer === "") {
            setSelectedServer(ServerSlugs.Aegwynn)
        }
    }, [selectedServer])

    function closeModalAndUpdateGuilds() {
        let query = queryString.parseUrl(window.location.href)
        if (query?.query?.guild)
            query = query.query.guild = query.query.guild === "string" ? [`${query.query.guild}`] : query.query.guild

        let urlString = '?guild='


        for (let i = 0; i < potentialGuilds.length; i++) {
            urlString += `${potentialGuilds[i].URL}${i + 1 < potentialGuilds.length ? "&guild=" : ""}`
        }

        window.history.replaceState('beep', '', urlString);

        setGuilds({ ...guilds, Custom: potentialGuilds })
        setIsOpen(false)
    }

    if (!isOpen)
        return <div className="custom-guilds-wrapper" onClick={() => setIsOpen(!isOpen)}>
            <div className="custom-guild-svg-wrapper">
                <img src={UserPlus} alt="plus icon" className="add-custom-logo" />
            </div>
        </div>

    return <div className="custom-guilds-modal-backdrop">
        <div className="custom-guilds-form">
            <p className="whiteTextCenter" style={{ fontWeight: 800 }}>Select your server and type in your guild name.</p>
            <p className="whiteTextCenter" style={{ fontWeight: 800 }}>Bookmark this after 'cause I haven't bothered to add logins yet.</p>
            <div className="custom-guilds-inputs">
                <select value={selectedServer}
                    onChange={e => setSelectedServer(e.target.value)}>
                    {selectOptions}
                </select>
                <input
                    className="custom-guilds-input"
                    value={guildInput}
                    onChange={e => setGuildInput(e.target.value)} />
                <img src={UserPlus}
                    alt="plus icon"
                    className="add-custom-guild"
                    onClick={() => setPotentialGuilds({ type: "add", newGuild: guildInput, newServer: selectedServer })} />
            </div>
            <div className="potential-guilds-container">
                {potentialGuilds?.map(guild => { return <CustomGuild guild={guild} removeGuild={() => setPotentialGuilds({ type: "remove", guild: guild })} /> })}
            </div>
            <div className="custom-guilds-done" onClick={() => closeModalAndUpdateGuilds()}>
                Done!
            </div>
        </div>
    </div>

}


function modifyGuilds(state, action) {
    if (action.type === 'add' && action.newGuild && action.newGuild.length > 0) {
        // lazy way to prevent adding the same guild 
        for (let i = 0; i < state.length; i++) {
            if (state[i].Name.toLowerCase() === action.newGuild.toLowerCase())
                return [...state]
        }
        let URL = `${action.newGuild}/${action.newServer}`
        return [...state, { "Name": action.newGuild, URL: URL }]
    }
    if (action.type === 'remove') {
        return state.filter(guild => guild.Name !== action.guild.Name)
    }
    if (action.type === 'update') {
        return [...state, ...action.guilds]
    }
}

function CustomGuild({ guild, removeGuild }) {
    return <div className="custom-guild-item">
        <div className="whiteTextCenter" style={{ fontWeight: 800 }}>{guild.Name}</div>
        <img src={UserMinus}
            alt="plus icon"
            className="add-custom-guild"
            onClick={() => removeGuild({ type: "remove", guild: guild })} />
    </div>
}