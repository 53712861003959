import React, { useState, useEffect, useReducer } from "react";
import Fight from "./Fight.js";
import { useInterval } from "../functions/functions";
let defaultSort = 'Ascending'

export default function Report(report) {
  const [reportDetail, setReportDetail] = useState(null);
  const [timerCount, setTimerCount] = useState(0);
  const [sort, setSort] = useReducer(sortClicked, { type: 'Pulls', direction: defaultSort })

  useInterval(() => {
    setTimerCount(timerCount + 1);
  }, 240000);

  let sortedFights = sortFights(sort, reportDetail, report)

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      const url = `https://www.warcraftlogs.com:443/v1/report/fights/${report.id}?api_key=${report.api_key}`;
      try {
        const result = await (await fetch(url)).json();
        if (!ignore) {
          setReportDetail(result);
        }
      } catch (err) {
        console.error("Fix this, error from fetching reports");
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
  }, [timerCount, report.id, report.api_key]);

  try {
    if (reportDetail?.fights?.length < 1)
      return (
        <React.Fragment>
          <thead>
            <tr className="raidnightTableHeaderRow">
              <th colSpan="6">
                <a
                  title={`This report lovingly created by: ${report.owner}`}
                  href={`https://www.warcraftlogs.com/reports/${report.id}`}
                >
                  {report.title.length > 50 ? `${report.title.substring(0, 50)}...` : report.title}
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="6">
                <span className="legendary">There's a log here, but no fights!</span>
              </td>
            </tr>
          </tbody>
        </React.Fragment>
      );
    return (
      <React.Fragment>
        <thead>
          <tr className="raidnightTableHeaderRow">
            <th colSpan="6" className="">
              <a
                title={`This report lovingly created by: ${report.owner}`}
                href={`https://www.warcraftlogs.com/reports/${report.id}`}
                className="report-title"
              >
                {report.title.length > 50 ? `${report.title.substring(0, 50)}...` : report.title}
              </a>
            </th>
          </tr>
        </thead>
        <thead>
          <tr className="raidnightTableHeaderRow">
            <th className="whiteTextCenter interactable-header" onClick={() => { setSort({ type: 'Pulls' }) }}>Pulls</th>
            <th className="whiteTextCenter interactable-header" onClick={() => { setSort({ type: 'Time' }) }}>Time</th>
            <th className="whiteTextCenter interactable-header" onClick={() => { setSort({ type: 'Diff' }) }}>Diff</th>
            <th className="whiteTextCenter interactable-header" onClick={() => { setSort({ type: 'Fight' }) }}>Fight</th>
            <th className="whiteTextCenter interactable-header" onClick={() => { setSort({ type: '%' }) }}>%</th>
            <th className="whiteTextCenter interactable-header" onClick={() => { setSort({ type: 'Length' }) }}>Length</th>
          </tr>
        </thead>
        <tbody>
          {reportDetail ? (
            [sortedFights]
          ) : (
            <tr>
              <td colSpan="6">
                <span className="legendary">Loading . . .</span>
              </td>
            </tr>
          )}
        </tbody>
      </React.Fragment>
    );
  } catch (err) {
    console.log(err);
    return <span className="legendary">Incorrect API Key or something has gone terribly wrong.</span>;
  }
}

function sortClicked(state, action) {
  let newDirection = defaultSort;

  if (state.type === action.type)
    newDirection = state.direction === "Ascending" ? "Descending" : "Ascending"
  else
    newDirection = state.direction

  return { type: action.type, direction: newDirection }
}


function sortFights(sort, reportDetail, reportInfo) {
  let reportIndex = 0;

  return reportDetail?.fights
    .filter((fight) => fight.boss > 0)
    .map(fight => {
      reportIndex += 1;
      fight.indexed = reportIndex;
      return fight;
    })
    .sort((a, b) => {
      let order = sort.direction === 'Descending' ? -1 : 1;
      switch (sort.type) {
        case "Pulls":
          return (a.id - b.id) * order;
        case "Time":
          return (a.start_time - b.start_time) * order;
        case "Diff":
          return (a.zoneDifficulty - b.zoneDifficulty) * order;
        case "Fight":
          return a.name.localeCompare(b.name, undefined, { numeric: true }) * order;
        case "%":
          return (a.fightPercentage - b.fightPercentage) * order;
        case "Length":
          let a_length = a.end_time - a.start_time;
          let b_length = b.end_time - b.start_time;
          return (a_length - b_length) * order;
        default:
          return false;
      }
    })
    .map(function (fight) {
      return (
        <tr key={fight.id} className={`tableRow${fight.indexed % 2}`}>
          <Fight {...fight} reportID={reportInfo.id} reportStart={reportInfo.start} reportIndex={fight.indexed} />
        </tr>
      );
    })
}