import React, { useState } from "react";
import { addDays } from "./functions/functions";
import GuildRaidnight from "./components/GuildRaidnight.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import queryString from "query-string";
import "./index.css";
import CategoryButton from "./components/CategoryButton";
import TwitchIntegration from "./components/TwitchIntegration";
import CustomGuildForm from "./components/CustomGuildForm.js";
import { useQuery } from "@tanstack/react-query";
import getBestProg from "./queries/getBestProg.js";
import getConfig from "./queries/getConfig.js";

// secret key pls don't steal
const API_KEY = "ce097b52c6a0d2d6b81d526fba53da18";

const INIT_GUILDS = {
    Imba: [
        { Name: "Same", URL: "Same/lightnings-blade" },
        { Name: "Imba", URL: "Imba/lightnings-Blade" },
    ],
    Regular: [
        { Name: "Denial of Service", URL: "Denial%20of%20Service/Burning-Blade" },
        { Name: "Fluff and Blood", URL: "Fluff%20and%20Blood/Burning-Blade" },
        { Name: "Vindicatum", URL: "Vindicatum/Icecrown" },
    ],
    Kirby: [
        { Name: "Nexus Sx", URL: "Nexus%20Sx/Tichondrius" },
        { Name: "Ghost Gaming", URL: "Ghost%20Gaming/Tichondrius" },
        { Name: "Rude", URL: "Rude/Illidan" },
    ],
    Others: [
        // { Name: "Unrivaled", URL: "Unrivaled/Stormreaver" },
        // { Name: "Unholy Trinity", URL: "Unholy%20Trinity/Burning-Blade" },
        { Name: "Nurfed", URL: "Nurfed/Tichondrius" },
        // { Name: "Spike Flail", URL: "Spike%20Flail/MalGanis" },
        { Name: "Warpath", URL: "Warpath/Illidan" },
        // { Name: "Serious Face", URL: "Serious%20Face/Illidan" },
        { Name: "Aero", URL: "Aero/Tichondrius" },
        // { Name: "Dark Skies", URL: "Dark%20Skies/Hyjal" },
    ],
    Toastie: [{ Name: "Toastie", URL: "Toastiekins", isPersonal: true }],
    Custom: []
};

var queryInput = queryString.parseUrl(window.location.href)

if (queryInput?.query?.guild) {
    let guildQuery = typeof queryInput.query.guild === "string" ? [`${queryInput.query.guild}`] : queryInput.query.guild;

    try {
        INIT_GUILDS["Custom"] = guildQuery.map(e => {
            let name = e.split('/')[0]
            let url = e.replace(' ', '%20')

            return { Name: name, URL: url }
        })
    } catch (err) {
        console.log(`Error with parsing query string! Tell Toaster ${err}`)
    }
}



console.log("https://www.youtube.com/watch?v=6n3pFFPSlW4");


export default function RaidTracker() {
    const [startDate, setDate] = useState(window.isDebug ? new Date('01/03/2024') : new Date()); //state for datepicker
    const [mode, setMode] = useState(localStorage.getItem("mode") || "Kirby");
    const [GUILDS, setGuilds] = useState(INIT_GUILDS);
    useQuery({ queryKey: ['bestprog'], queryFn: getBestProg, refetchInterval: 60000 })
    useQuery({ queryKey: ['config'], queryFn: getConfig, refetchInterval: 600000 })

    let guildComponents = [];

    if (!mode) {
        setMode("Regular");
    }

    if (GUILDS[mode])
        guildComponents = GUILDS[mode].map((guild) => {
            return (
                <div className={`logpanels`} key={guild.Name}>
                    <GuildRaidnight datePicked={startDate} guild={guild.Name} guildURL={guild.URL} isPersonal={guild.isPersonal} api_key={API_KEY} />
                </div>
            );
        });

    return (
        <React.Fragment>
            <div className="controlBar">
                <div className="datepickerContainer">
                    <button
                        className="datepickerButton"
                        onClick={() => {
                            setDate(addDays(startDate, -1));
                        }}
                    >
                        &#60;
                    </button>
                    <DatePicker todayButton={"Today"} selected={startDate} onChange={(date) => setDate(date)} />
                    <button className="datepickerButton" onClick={() => setDate(addDays(startDate, +1))}>
                        &#62;
                    </button>
                    <div className="mode-select-wrapper">
                        <CategoryButton setter={setMode} label={"Default"} mode={"Regular"} currentMode={mode} />
                        <CategoryButton setter={setMode} label={"Contenders"} mode={"Kirby"} currentMode={mode} />
                        <CategoryButton setter={setMode} label={"Others"} mode={"Others"} currentMode={mode} />
                        <CategoryButton setter={setMode} label={"Custom"} mode={"Custom"} currentMode={mode} />

                    </div>
                </div>
                <div className="hr" />
            </div>
            <div className="bodyContent">
                <div className="bgImage"></div>
                <div className="logpanelContainer">{guildComponents}</div>
            </div>
            <TwitchIntegration />
            {mode === 'Custom' ? <CustomGuildForm guilds={GUILDS} setGuilds={setGuilds} /> : <React.Fragment></React.Fragment>}
        </React.Fragment>
    );
}
